<template>
  <div class="sk-page">
    <simple-table
      ref="simple-table"
      :table-column="tableColumn"
      :table-data="tableData"
      :table-page-info="tablePageInfo"
      :table-data-count="tableDataTotalCount"
      @page-info-change="handlePageInfoChange"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
        </template>
      </simple-form>
      <template
        slot="fileName"
        slot-scope="scope"
      >
        {{ getFileName(scope.row) }}
      </template>
      <template
        slot="iexpType"
        slot-scope="scope"
      >
        {{ $getDictLabel({type: 'iexp_type', value: scope.row.iexpType}) }}
      </template>
      <template
        slot="iexpStatus"
        slot-scope="scope"
      >
        {{ $getDictLabel({type: 'iexp_status', value: scope.row.iexpStatus}) }}
      </template>

      <template
        slot="startDate"
        slot-scope="scope"
      >
        {{ scope.row.startDate ? $moment(scope.row.startDate).format('YYYY-MM-DD HH:mm:ss') : '' }}
      </template>

      <template
        slot="endDate"
        slot-scope="scope"
      >
        {{ scope.row.endDate ? $moment(scope.row.endDate).format('YYYY-MM-DD HH:mm:ss') : '' }}
      </template>

      <template
        slot="operation"
        slot-scope="scope"
      >
        <el-button
          v-permission="'system_task:view'"
          circle
          icon="el-icon-search"
          :title="$t('operation.view')"
          @click="handleViewClick(scope.row)"
        />
        <el-button
          v-permission="'system_task:export'"
          circle
          :disabled="scope.row.iexpStatus !=='iexp_status_success' || scope.row.iexpType==='iexp_type_standard'"
          icon="el-icon-download"
          :title="$t('operation.download')"
          @click="downloadExcel(scope.row)"
        />
      </template>
    </simple-table>
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />
  </div>
</template>
<script>
/** 混入分页信息及数据总数信息 */
import pageMixins from '@/components/base/simple-table/mixin'
import detailDialog from './detail'
const BASEURL = {
  list: '/system/iexp/task/list',
  downloadExcel: '/download/iexp/task/download'
}
export default {
  // name: 'SystemLog',
  components: { detailDialog },
  mixins: [pageMixins],
  data () {
    return {
      searchModel: {},
      /** 表格数据 */
      tableData: [],
      rowData: {},
      /** 是否显示详情页 dialog */
      isShowDetailDialog: false,
      /** 是否为查看数据 */
      isViewFlag: false
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'iexpStatus',
          label: this.$t('system_task.status'),
          type: 'Select',
          component: { optionList: this.$getDictList('iexp_status'), filterable: true, clearable: true },
          hideCode: true
        },
        { slotName: 'tableOperation', col: { span: 18, style: { textAlign: 'right' } }, labelWidth: '0' }
      ]
    },
    tableColumn () {
      return [
        { prop: 'name', label: this.$t('system_task.name'), width: 250 },
        { prop: 'fileName', label: this.$t('system_task.fileName'), slotName: 'fileName', width: 250 },
        { prop: 'message', label: this.$t('system_task.message'), minWidth: 200 },
        { prop: 'iexpType', label: this.$t('system_task.operationStatus'), width: 120, slotName: 'iexpType' },
        // { prop: 'percentage ', label: this.$t('system_task.percentage'), width: 120 },
        { prop: 'iexpStatus', label: this.$t('system_task.status'), width: 120, slotName: 'iexpStatus' },
        { prop: 'startDate', label: this.$t('system_task.beginUploadTime'), slotName: 'startDate', width: 150 },
        { prop: 'endDate', label: this.$t('system_task.endUploadTime'), width: 150, slotName: 'endDate' },
        { label: this.$t('field.operation'), slotName: 'operation', width: 80, fixed: 'right' }
      ]
    }
  },
  /**
   * 注入到子组件
   */
  provide () {
    return { handleDataSearch: this.handleDataSearch }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    getFileName (row) {
      if (row.iexpType === 'iexp_type_imp') {
        if (row.fileName && row.fileName.length > 32) {
          return row.fileName.substring(32)
        }
      }
      return row.fileName
    },
    handleDataSearch () {
      let searchFilter = {
        page: this.tablePageInfo,
        filter: []
      }
      if (this.searchModel.iexpStatus) {
        searchFilter.filter.push({ left: 'iexpStatus', operate: '=', right: this.searchModel.iexpStatus })
      }
      let loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios.post(BASEURL.list, searchFilter)
        .then(resp => {
          let respData = resp.data
          this.tableData = respData.list
          this.tablePageInfo.pageNo = respData.pageNo
          this.tablePageInfo.pageSize = respData.pageSize
          this.tableDataTotalCount = respData.count
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    downloadExcel (row) {
      this.$utils.fileSaveAs(BASEURL.downloadExcel, { id: row.id })
    },
    handleViewClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = true
      this.isShowDetailDialog = true
    }
  }
}
</script>
<style lang="less" scoped>
  .sk-page {
    width: 100%;
    height: 100%;
  }
</style>
