<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    fullscreen
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_task.detailDialog') }}
    </div>
    <div class="dialog_con">
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="120px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      />

      <div class="message_con">
        <simple-table
          :table-column="tableColumn"
          :table-data="tableData"
          :pageable="false"
        />
      </div>
    </div>
    <div slot="footer">
      <el-button
        @click="handleDialogClose"
      >
        {{ $t('operation.close') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const BASEURL = {
  get: '/system/iexp/task/get '
}
export default {
  name: 'SystemTaskDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: { },
      tableData: []
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },

    type () {
      return this.$getDictList('iexp_type')
    },
    status () {
      return this.$getDictList('iexp_status')
    },

    formField () {
      return [
        {
          prop: 'fileName',
          label: this.$t('system_task.fileName'),
          type: 'Input'
        },
        {
          prop: 'iexpType',
          label: this.$t('system_task.operationStatus'),
          type: 'Select',
          component: { optionList: this.type }
        },
        {
          prop: 'iexpStatus',
          label: this.$t('system_task.status'),
          type: 'Select',
          component: { optionList: this.status }
        },
        {
          prop: 'message',
          label: this.$t('system_task.message'),
          type: 'Input'
        }
      ]
    },
    tableColumn () {
      return [
        { prop: 'message', label: this.$t('system_task.message') }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },

    handleDialogOpen () {
      if (!this.detailData.id) return
      let loadingFlag = this.$loading({
        target: this.$el.querySelector('.el-dialog')
      })

      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          let respData = resp.data
          let fileName = respData.iexpType === 'iexp_type_imp' ? respData.fileName.substring(32) : respData.fileName
          this.formModel = Object.assign(respData, { fileName })
          this.tableData = respData.itemList
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = { }
      this.tableData.splice(0)
    }
  }
}
</script>
<style lang="less" scoped>
.dialog_con {
  display: flex;
  height: 100%;
  flex-direction: column;

  .message_con {
    flex: 1;
  }
}
</style>
